import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type FunctionComponent,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatParams, formatSignUpPayload } from './helpers';
import type { ReferralSignUpFieldValues } from './types';

export interface ReferralSignUpContextProps {
  children: ReactNode;
}

export type ReferralSignUpPage =
  | 'holding'
  | 'partner'
  | 'kanda'
  | 'signup'
  | 'success';

export interface ReferralSignUpContextType {
  page: ReferralSignUpPage;
  setPage: Dispatch<SetStateAction<ReferralSignUpPage>>;
  id?: string;
  email?: string;
  from?: string;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  submit: (formValues: ReferralSignUpFieldValues) => void;
}

export const ReferralSignUpContext = createContext<ReferralSignUpContextType>({
  page: 'holding',
  setPage: () => {},
  disabled: false,
  setDisabled: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
  submit: () => {},
});

export const useReferralSignUpContext = () => useContext(ReferralSignUpContext);

const ReferralSignUpProvider: FunctionComponent<ReferralSignUpContextProps> =
  function ({ children }) {
    const search = window?.location?.search;

    const [page, setPage] = useState<ReferralSignUpPage>('holding');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>();

    const { id } = useSelector(selectors.getPathKey);
    const dispatch = useAppDispatch();
    const onError = useApiError('Error signing up');
    const { setUserId } = useAmplitude();

    const params = useMemo(() => formatParams(search), [search]);

    const from = useMemo(() => {
      if (!params) return undefined;
      return params?.from || undefined;
    }, [params]);

    const submit = useCallback(
      (formValues: ReferralSignUpFieldValues) => {
        const body = formatSignUpPayload(formValues);
        setIsSubmitting(true);
        dispatch(
          actions.postMe({
            body,
            protectedRequest: true,
            params: {
              x_kanda_protected: 'yes',
            },
            onError: (error) => {
              setIsSubmitting(false);
              onError(error);
            },
            onSuccess: (signUpBody) => {
              setIsSubmitting(false);
              const fbq = window?.fbq;
              if (fbq) fbq('track', 'CompleteRegistration');
              if (signUpBody?.id) setUserId(signUpBody.id);
              setPage('success');
            },
          }),
        );
      },
      [dispatch, onError, setUserId],
    );

    useEffect(() => {
      if (!id) return;
      if (id === 'kanda-referrals') {
        setPage('kanda');
        return;
      }
      setPage('partner');
    }, [id]);

    useEffect(() => {
      if (!params) return;
      const paramEmail = params?.email || undefined;
      if (!paramEmail) return;
      setEmail(paramEmail);
    }, [params]);

    const values = useMemo(
      () => ({
        page,
        setPage,
        id,
        email,
        from,
        disabled,
        setDisabled,
        isSubmitting,
        setIsSubmitting,
        submit,
      }),
      [page, setPage, id, email, from, disabled, isSubmitting, submit],
    );

    return (
      <ReferralSignUpContext.Provider value={values}>
        {children}
      </ReferralSignUpContext.Provider>
    );
  };

export default ReferralSignUpProvider;
