/**
 * Conifguration related to the app
 */

export type PageKeys =
  | 'home'
  | 'login'
  | 'loginSso'
  | 'logout'
  | 'jobs'
  | 'job'
  | 'createJob'
  | 'createJobFromDraft'
  | 'archive'
  | 'staff'
  | 'staffLogin'
  | 'forgotPassword'
  | 'forgotPasswordConfirmation'
  | 'resetPassword'
  | 'resetPasswordConfirmation'
  | 'authLink'
  | 'myAccount'
  | 'directorVerification'
  | 'companyInformation'
  | 'jobSettings'
  | 'accountSettings'
  | 'teamSettings'
  | 'changeEmail'
  | 'changePassword'
  | 'billingSuccess'
  | 'subscription'
  | 'subscriptionReturn'
  | 'subscriptionSuccess'
  | 'notificationPreferences'
  | 'claimAccount'
  | 'claimAccountResent'
  | 'exampleJob'
  | 'leadAccept'
  | 'signUp'
  | 'referrals'
  | 'referralsForm'
  | 'referralSignUp'
  | 'identify'
  | 'identifySuccess'
  | 'setupCompany'
  | 'setupPartnerCompany'
  | 'verifyEmail'
  | 'verifyEmailSuccess'
  | 'veriff'
  | 'onboardingSteps'
  | 'onboarding'
  | 'customCode'
  | 'updateYourWebsiteRedirect'
  | 'updateYourWebsite'
  | 'developerRedirect'
  | 'developer'
  | 'updateYourWebsite'
  | 'advertisingRedirect'
  | 'advertising'
  | 'invite'
  | 'inviteForm'
  | 'inviteSummary'
  | 'setupAccount'
  | 'setupRates'
  | 'setupSubscription'
  | 'accountInReview'
  | 'enterpriseRates'
  | 'enterpriseRatesSuccess'
  | 'marketingPackage';

export const URLS: Record<PageKeys, string> = {
  home: '/',
  login: '/login',
  logout: '/logout',
  jobs: '/jobs',
  job: '/job-details/:id',
  createJob: '/create-job',
  createJobFromDraft: '/create-job/:id',
  archive: '/archive',
  staff: '/staff',
  staffLogin: '/staff-login',
  forgotPassword: '/forgot-password',
  forgotPasswordConfirmation: '/forgot-password/confirmation',
  resetPassword: '/reset-password',
  resetPasswordConfirmation: '/reset-password/confirmation',
  authLink: '/auth-link',
  myAccount: '/my-account',
  directorVerification: '/my-account/directory-verification',
  companyInformation: '/my-account/company-information',
  jobSettings: '/my-account/job-settings',
  accountSettings: '/my-account/account-settings',
  teamSettings: '/my-account/team-settings',
  changeEmail: '/my-account/account-settings/change-email',
  changePassword: '/my-account/account-settings/change-password',
  billingSuccess: '/billing/success',
  subscription: '/my-account/subscription',
  notificationPreferences: '/my-account/notification-preferences',
  subscriptionReturn: '/subscription',
  subscriptionSuccess: '/subscription/success',
  claimAccount: '/claim-account',
  claimAccountResent: '/claim-account/resent-email',
  loginSso: '/login/sso',
  exampleJob: '/example-job',
  leadAccept: '/lead-accept/:id',
  signUp: '/signup',
  referrals: '/referrals',
  referralsForm: '/referrals/invite',
  referralSignUp: '/referral-signup/:id',
  identify: '/identify/:id',
  identifySuccess: '/identify/:id/success',
  setupCompany: '/setup-company',
  setupPartnerCompany: '/setup-company/:id',
  verifyEmail: '/verify-email',
  verifyEmailSuccess: '/verify-email/success',
  veriff: '/verify',
  onboardingSteps: '/onboarding/:id',
  onboarding: '/onboarding',
  customCode: '/custom-code',
  updateYourWebsiteRedirect: '/welcome/update-your-website',
  updateYourWebsite: '/welcome/update-your-website/:id',
  developerRedirect: '/welcome/developer',
  developer: '/welcome/developer/:id',
  advertisingRedirect: '/welcome/advertising',
  advertising: '/welcome/advertising/:id',
  invite: '/welcome/invite',
  inviteForm: '/welcome/invite/form',
  inviteSummary: '/welcome/invite/summary',
  setupAccount: '/setup/account',
  setupRates: '/setup/rates',
  setupSubscription: '/setup/subscription',
  accountInReview: '/account-in-review',
  enterpriseRates: '/enterprise-rates',
  enterpriseRatesSuccess: '/enterprise-rates/success',
  marketingPackage: '/marketing-package',
};

export const APP_ENV = process.env.REACT_APP_ENV || 'qa';

export const TERMS_URL = 'https://www.getkanda.com/legal-page';

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://dashboard-qa.kanda.co.uk/';

// Refresh token timer of 10 minutes
export const REFRESH_TOKEN_TIMER = 1 * 60 * 1000;
