import { useContext, useMemo } from 'react';

import type { UserType } from '@kanda-libs/ks-frontend-services';

import useIsSubscribed from '../../../../hooks/useIsSubscribed';
import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { isLoading, jobs, company, hasActiveFilters, archive, showOptions } =
    useContext(Context);

  const setupInProgress = useMemo(() => {
    if (!company) return false;
    if (['registered', 'onboarded'].includes(company?.lifecycle as string))
      return true;
    if (company?.lifecycle === 'verified') return false;
    if (!company?.users || company?.users?.length === 0) return true;

    const directors = company?.users?.reduce((users: UserType[], user) => {
      if (!user?.director_info) return users;
      users.push(user);
      return users;
    }, []);
    const notUpdated = directors.reduce((not: UserType[], director) => {
      if (
        director?.email === 'no-reply@kanda.co.uk' &&
        director?.director_info?.verification_status !== 'verified'
      )
        return not;
      not.push(director);
      return not;
    }, []);

    return notUpdated.length === 0;
  }, [company]);

  const { isSubscribed } = useIsSubscribed();

  const showSubscriptionBanner =
    !isLoading && !setupInProgress && !archive && !isSubscribed;

  return children({
    isLoading,
    jobs,
    showOptions,
    setupInProgress,
    hasActiveFilters,
    showSubscriptionBanner,
    archive,
  });
};

ContainerComponent.displayName = 'Banner-container';

export default ContainerComponent;
