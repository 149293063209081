import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useCallback } from 'react';
import type { WindowWithFbq } from './types';

type FBEventType = 'PageView' | 'Lead' | 'CompleteRegistration' | 'Subscribe';

type FBEventProps = StringIndexedObject;

export type FacebookPixelHook = (
  eventType: FBEventType,
  eventProps?: FBEventProps,
) => void;

function useFacebookPixel(): FacebookPixelHook {
  const sendEvent = useCallback(
    (eventType: FBEventType, eventProps?: FBEventProps) => {
      const fbq = (window as unknown as WindowWithFbq)?.fbq;
      if (!fbq) return;
      fbq('track', eventType, eventProps);
    },
    [],
  );

  return sendEvent;
}

export default useFacebookPixel;
