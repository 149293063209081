import { Icon, Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';

export interface FeaturesProps {
  features: string[];
  isLoading?: boolean;
}

const Features: FunctionComponent<FeaturesProps> = ({
  features,
  isLoading = false,
}) => {
  const allFeatures = isLoading ? ['f1', 'f2', 'f3'] : features;

  return (
    <div className="flex flex-col">
      {allFeatures.map((feature: string) => (
        <div className="flex flex-row mt-3 first:mt-0" key={feature}>
          <div className="flex w-5 h-5 min-w-5 min-h-5 md:w-6 md:h-6 md:min-w-6 md:min-h-6 rounded-full bg-green-200 mr-3 md:mr-4 my-auto">
            <Icon
              icon="check"
              size={12}
              stroke="green-600"
              className="m-auto"
            />
          </div>
          <Text
            isLoading={isLoading}
            text={feature}
            className="text-14-22 md:text-16-24 text-neutral-700 my-auto"
            skeletonProps={{
              width: 200,
            }}
          />
        </div>
      ))}
    </div>
  );
};
export default Features;
