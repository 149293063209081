import type { SubscriptionCardProps } from './SubscriptionCard';

export const STANDARD_YEARLY_CARD: SubscriptionCardProps = {
  variant: 'highlight',
  title: 'Yearly',
  subscription: {
    amount: 750,
    frequency: 'year',
  },
  bullets: ['excl. VAT', 'over 30% cheaper'],
  features: [
    'Priority set up',
    'Pay securely by card',
    'Access to Kanda for 12 months',
  ],
  buttonProps: {
    id: 'setup-subscription-yearly',
    label: 'Yearly',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const STANDARD_MONTHLY_CARD: SubscriptionCardProps = {
  variant: 'standard',
  title: 'Monthly',
  subscription: {
    amount: 90,
    frequency: 'month',
  },
  bullets: ['excl. VAT', '12-month contract paid monthly'],
  features: [
    'Paid monthly via PremiumCredit',
    'Backed by personal gurantee',
    'Access to Kanda for 12 months',
  ],
  buttonProps: {
    id: 'setup-subscription-monthly',
    label: 'Monthly',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const HUMM_YEARLY_CARD: SubscriptionCardProps = {
  variant: 'highlight',
  title: 'Yearly',
  subscription: {
    amount: 500,
    frequency: 'year',
  },
  bullets: ['excl. VAT'],
  features: [
    'Priority set up',
    'Pay securely by card',
    'Access to Kanda for 12 months',
  ],
  buttonProps: {
    id: 'setup-humm-subscription-yearly',
    label: 'Subscribe',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const HUMM_MONTHLY_CARD: SubscriptionCardProps = {
  variant: 'standard',
  title: 'Monthly',
  subscription: {
    amount: 55,
    frequency: 'month',
  },
  bullets: ['excl. VAT', '12-month contract paid monthly'],
  features: [
    'Paid monthly via PremiumCredit',
    'Backed by personal gurantee',
    'Access to Kanda for 12 months',
  ],
  buttonProps: {
    id: 'setup-humm-subscription-monthly',
    label: 'Monthly',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const REFERRAL_DISCOUNT = 50;
