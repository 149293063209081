import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

export const financeRateIsSelectable = (financeRate: FinanceRate): boolean =>
  financeRate.apr_type === 'INTEREST_FREE' &&
  (financeRate?.duration || 12) <= 10;

const financeRateIsWithinJobTotalBounds = (
  financeRate: FinanceRate,
  jobTotal: number,
): boolean => {
  if (!financeRate.min_job_value && !financeRate.max_job_value) return true;

  return (
    jobTotal >= ((financeRate.min_job_value as number) || 0) &&
    jobTotal <= (financeRate.max_job_value as number)
  );
};

const financeRateIsWithinDepositPercentageBounds = (
  financeRate: FinanceRate,
  depositPercentage: number,
): boolean => {
  if (!financeRate.min_deposit_pct && !financeRate.max_deposit_pct) return true;

  // Deposit percentage is stored as a pence value to avoid
  // issues with floating point numbers
  const formattedDepositPercentage = depositPercentage * 100;

  return (
    formattedDepositPercentage >= 0 &&
    formattedDepositPercentage <= (financeRate.max_deposit_pct as number)
  );
};

const financeRateIsWithinDepositAmountBounds = (
  financeRate: FinanceRate,
  depositAmount: number,
): boolean => {
  if (!financeRate.min_deposit_value && !financeRate.max_deposit_value)
    return true;

  return (
    depositAmount >= ((financeRate.min_deposit_value as number) || 0) &&
    depositAmount <= (financeRate.max_deposit_value as number)
  );
};

const financeRateIsWithinDepositBounds = (
  financeRate: FinanceRate,
  depositPercentage: number,
  depositAmount: number,
): boolean =>
  financeRateIsWithinDepositPercentageBounds(financeRate, depositPercentage) &&
  financeRateIsWithinDepositAmountBounds(financeRate, depositAmount);

export const financeRateIsWithinBounds = (
  financeRate: FinanceRate,
  jobTotal: number,
  depositPercentage: number,
  depositAmount: number,
): boolean => {
  const withinJobTotalBounds = financeRateIsWithinJobTotalBounds(
    financeRate,
    jobTotal,
  );

  const withinDepositBounds = financeRateIsWithinDepositBounds(
    financeRate,
    depositPercentage,
    depositAmount,
  );

  if (!withinJobTotalBounds) return false;
  if (!withinDepositBounds) return false;

  return true;
};

const rateIsAboveDepositPercentageMinimum = (
  financeRate: FinanceRate,
  depositPercentage: number,
): boolean => {
  if (!financeRate.min_deposit_pct) return true;
  const formattedPercentage = depositPercentage * 100;
  return formattedPercentage >= (financeRate.min_deposit_pct || 0);
};

const rateIsAboveDepositAmountMinimum = (
  financeRate: FinanceRate,
  depositAmount: number,
): boolean => {
  if (!financeRate.min_deposit_value) return true;
  return depositAmount >= ((financeRate.min_deposit_value as number) || 0);
};

export const depositAboveMinimum = (
  financeRate: FinanceRate,
  depositPercentage: number,
  depositAmount: number,
): boolean => {
  const aboveDepositPercentageMinimum = rateIsAboveDepositPercentageMinimum(
    financeRate,
    depositPercentage,
  );
  const aboveDepositAmountMinimum = rateIsAboveDepositAmountMinimum(
    financeRate,
    depositAmount,
  );
  return aboveDepositPercentageMinimum && aboveDepositAmountMinimum;
};
