import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  Company,
  services,
  useSubmit,
  type Service,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import useFacebookPixel from 'hooks/useFacebookPixel';
import { useCallback, useMemo, useState } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSubscriptionContext } from './context';
import { formatContractName } from './helpers';
import type { ContractFieldValues } from './types';

export interface SignContractFormHook {
  form: UseFormReturn<ContractFieldValues>;
  onSubmit: (formValues: StringIndexedObject<ContractFieldValues>) => void;
  isSubmitting: boolean;
}

export default function useSignContractForm(): SignContractFormHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { contract, bankAccount } = useSubscriptionContext();
  const { company } = useCurrentCompany();
  const { showError, showSuccess } = useToast();
  const dispatch = useAppDispatch();
  const onError = useApiError('Error storing signed contract');
  const sendEvent = useFacebookPixel();
  const { push } = useHistory();

  const { submit: updateSheet } = useSubmit(
    services.subsSheet.update as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
  );

  const defaultValues: ContractFieldValues = useMemo(
    () => ({
      iar: false,
      terms_and_conditions: false,
    }),
    [],
  );

  const form = useForm<ContractFieldValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (fv: StringIndexedObject<ContractFieldValues>) => {
      const formValues = fv as unknown as ContractFieldValues;
      const cid = company?.id;
      if (
        !formValues.iar ||
        !formValues.terms_and_conditions ||
        !contract ||
        !cid
      ) {
        showError('Error with input information');
        return;
      }
      const name = formatContractName(contract);
      setIsSubmitting(true);
      dispatch(
        actions.postDocument({
          body: {
            content: contract.content,
            name,
          },
          onError: (error) => {
            setIsSubmitting(false);
            onError(error);
          },
          onSuccess: () => {
            updateSheet({ body: { cid } }).then(
              ({ error: sheetUpdateError }) => {
                if (sheetUpdateError) {
                  setIsSubmitting(false);
                  showError(
                    'Error updating your information - please refresh and try again. If this issue persists, please contact Kanda',
                  );
                  return;
                }
                dispatch(
                  actions.postCompanyBillingSuccess({
                    params: {
                      id: cid,
                      provider: 'premium_credit',
                    },
                    forceReload: true,
                    onError: () => {
                      showError(
                        'Error marking subscription as successful. Please contact Kanda.',
                      );
                    },
                    onSuccess: () => {
                      if (bankAccount) {
                        dispatch(
                          actions.putCompany({
                            body: {
                              ...company,
                              bank_account: {
                                account_name: bankAccount.account_name,
                                account_number: bankAccount.account_number,
                                sort_code: bankAccount.sort_code,
                              },
                            } as Company,
                            params: {
                              id: cid,
                            },
                          }),
                        );
                      }
                      showSuccess('Subscription processed successfully');
                      sendEvent('Subscribe');
                      push(URLS.onboarding);
                    },
                  }),
                );
              },
            );
          },
        }),
      );
    },
    [
      updateSheet,
      bankAccount,
      company,
      contract,
      dispatch,
      onError,
      push,
      sendEvent,
      showError,
      showSuccess,
    ],
  );

  return {
    form,
    onSubmit,
    isSubmitting,
  };
}
