import { Text } from '@kanda-libs/ks-design-library';
import { Calculator, SubHeaderPoints, Subscriptions } from '../../components';
import { useSubscriptionContext } from '../../context';
import { REFERRED_TITLE, STANDARD_TITLE } from './constants';

const SubscriptionsPage: React.FunctionComponent = function () {
  const { isLoading, referredByUser } = useSubscriptionContext();
  return (
    <>
      <Text
        isLoading={isLoading}
        text={referredByUser ? REFERRED_TITLE : STANDARD_TITLE}
        className="text-24-28-em md:text-40-48-em mb-6 md:mb-16"
      />
      <SubHeaderPoints />
      <Subscriptions />
      <Calculator />
    </>
  );
};

export default SubscriptionsPage;
