import {
  useFormContext,
  useWatch,
  type ValidationItems,
} from '@kanda-libs/ks-component-ts';

import { URLS } from '../../../../config';
import { removeWhitespaceAndNewline } from '../../../../utils';

const ContainerComponent = ({ children }) => {
  const { getValues, setValue } = useFormContext();

  const [email, password] = useWatch({
    name: ['email', 'password'],
  });

  const onEmailBlur = () =>
    setValue('email', removeWhitespaceAndNewline(getValues('email')));

  const emailValidation = {
    required: {
      value: true,
      message: 'Email address is required',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Email must be a valid email address',
    },
    validate: {
      value: () => getValues()?.email_validation !== 'invalid',
      message: 'Email address entered is invalid',
    },
  } as unknown as ValidationItems;

  const to = {
    pathname: URLS.signUp,
    state: {
      email,
      password,
    },
  };

  return children({
    to,
    onEmailBlur,
    emailValidation,
  });
};

ContainerComponent.displayName = 'Login-Content-container';

export default ContainerComponent;
