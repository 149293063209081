import { useMemo } from 'react';
import {
  useCurrentUser,
  type UserType,
} from '@kanda-libs/ks-frontend-services';
import {
  ONBOARDING_STEPS,
  STEPS,
} from './AccountVerificationExplanation-constants';

import useCurrentCompany from '../../../../../hooks/useCurrentCompany';
import {
  currentUserDirectorIsVerified,
  getAllDirectorsAreNotMe,
} from '../../../Onboarding-helpers';

export default function useAccountVerificationExplanation() {
  const { company, isLoading } = useCurrentCompany();
  const { user, isValidating } = useCurrentUser();

  const filterMethodArgs = useMemo(
    () => [company?.users || [], user?.email],
    [company, user],
  ) as [UserType[], string];

  const allDirectorsAreNotMe = getAllDirectorsAreNotMe(...filterMethodArgs);

  const currentUserVerified = currentUserDirectorIsVerified(
    ...filterMethodArgs,
  );

  const identifyStepToShow =
    allDirectorsAreNotMe || currentUserVerified
      ? ONBOARDING_STEPS.VERIFICATION_STATUS
      : ONBOARDING_STEPS.IDENTIFY;

  const initialStepIndex =
    company?.lifecycle === 'director_info_provided'
      ? STEPS.indexOf(identifyStepToShow)
      : 0;

  return {
    isLoading: isValidating || isLoading || !user?.email,
    initialStepIndex,
  };
}
